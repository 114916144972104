import React from 'react';
import { Layout } from '../../components';
import { CareerOpeningsViewProps } from '../../types';

const CareerOpeningsView: React.FunctionComponent<
  CareerOpeningsViewProps
> = ({}) => {
  const links = [
    {
      title: 'US Careers Opportunities',
      description: 'Explore openings in the United States.',
      href: 'https://uscareers-pinkerton.icims.com/jobs/search?mode=job&iis=Company+Website',
      cover: '/careers/openings-unitedstates.png',
    },
    {
      title: 'China Careers Opportunities',
      description: 'Explore openings in China.',
      href: 'https://chinacareers-pinkerton.icims.com/jobs/search?mode=job&iis=Company+Website',
      cover: '/careers/openings-china.png',
    },
    {
      title: 'Global Careers Opportunities',
      description: 'Explore openings outside of the United States and China.',
      href: 'https://careers-pinkerton.icims.com/jobs/search?mode=job&iis=Company+Website',
      cover: '/careers/openings-global.png',
    },
    {
      title: 'Employee Portal',
      href: 'https://employees-pinkerton.icims.com/',
      cover: '/careers/openings-internal.png',
    },
  ];

  return (
    <Layout
      title="Careers | Current Openings"
      description=""
      banner={{
        src: '/media/careers/careers.jpg',
        subtitle: 'Explore our current job openings',
        title: 'Pinkerton Careers',
      }}
      className="articleWithAsideLayout"
    >
      <div className="overview currentOpenings">
        <div className="wrapper">
          <header className="overview--header">
            <h2 className="overview--header-title">Why Choose Pinkerton</h2>
            <p className="overview--header-description">
              For many, Pinkerton is a company that answers a calling. A calling
              to serve and protect others, no matter what. With this unified
              sense of purpose, our global force of Pinkerton agents and support
              functions band together and become something more than colleagues
              — they become a family. No matter how far they are spread apart,
              Pinkertons are bonded together by their shared calling and their
              commitment to integrity, vigilance, and excellence.
            </p>
          </header>
        </div>
        <div className="articleWithPatternLayout">
          <div className="overview">
            <div className="wrapper">
              <div className="twoColSingle--main">
                <div className="currentOpenings--links">
                  {links.map((link, linkIndex) => (
                    <article key={linkIndex} className="postList--item">
                      <a
                        className="postList--item-navBtn"
                        href={link.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="postList--item-cover">
                          <img
                            src={link.cover}
                            alt={link.title}
                            width={1200}
                            height={600}
                          />
                        </div>
                        <div className="postList--item-overlay"></div>
                        <div className="postList--item-inner">
                          <h3 className="postList--item-title">{link.title}</h3>
                          {link.description && (
                            <p className="postList--item-description">
                              {link.description}
                            </p>
                          )}
                        </div>
                      </a>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-right"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7" />
                      </svg>
                    </article>
                  ))}
                </div>
                <p className="currentOpenings--aside-text">
                  <em>
                    Pinkerton is an equal opportunity employer to all applicants
                    and positions without regard to race/ethnicity, color,
                    national origin, ancestry, sex/gender, gender
                    identity/expression, sexual orientation, marital/prenatal
                    status, pregnancy/childbirth or related conditions,
                    religion, creed, age, disability, genetic information,
                    veteran status, or any protected status by local, state,
                    federal or country-specific law.
                  </em>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CareerOpeningsView;
